.nao-encontrado{
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nao-encontrado h1{
  font-size: 48px;
}

.nao-encontrado p{
  font-size: 24px;
}