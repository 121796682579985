header{
  background-color: #19C8DB;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0,0,0,.10);
}

.header-area{
  max-width: 1330px;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li{
  display: inline-block;
  margin: 0 20px;
}

.header-link-text{
  width: 80px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.header-link-text:hover{
  color: #ffffffcb;
}

.mobile-menu-icon{
  display: none;
  background-color: transparent;
  border: none;
}

.nav-links{
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.nav-links-mobile{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.nav-text-mobile{
  display: none;
}

.ativo{
  color: #0A4F57;
}

@media screen and (max-width: 995px) {

  .nav-links{
    display: none;
  }

  .nav-links-mobile{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    background-color:#15b7c9e8 ;
    padding: 0;
    right: 0;
    top: 100px;
    transition: all 0.5s ease-out;
    width: 100%;
    height: 40vh;
  }

  .nav-li{
    color: white;
    text-align: center;
    padding: 16px;
    transition: all 0.5 ease;
  }

  .mobile-menu-icon{
    display: block;
    position: absolute;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    top: 30px;
    right: 25px;
  }

  .nav-text-mobile{
    display: block;
    padding: 0;
  }

  .header-text-desktop{
    display: none;
  }

}

@media screen and (max-width: 1024px) {
  .header-area{
    margin: 0 15px;
  }
  
}

@media screen and (max-width: 768px) {
  .header-area{
    margin: 0 25px;
  }

  i{
    margin-right: 5px ;
  }
}

@media screen and (max-width: 600px) {
  .header-area{
    margin: 0 10px;
  }

  i{
    margin-right: 0 ;
  }

  .nav-links-mobile{
    height: 100vh;
  }
}
