.search-bar{
  background-color: white;
  width: 280px;
  padding: 10px;
  border-radius: 40px;
  margin: 0;
  display: flex;
  border: 1px solid #00000020;
  box-sizing: border-box;
}

.search-text{
  border: none;
  background: none;
  outline:none;
  padding: 0 10px;
  font-size: 16px;
  line-height: 40px;
  width: 90%;
  transition: 0.3s;
}

.search-button{
  border: none;
  background-color: #fff;
  cursor: pointer;
  border-left: 1px solid #ddd;
}
.search-text::-webkit-search-cancel-button{
  display: none;
}

.clear-button{
  cursor: pointer;
  border: none;
  background-color: transparent;
}