.filtro-area{
  max-width: 1440px;
}

.filtro-area h1{
  margin: 0 50px;
}

.filtro{
  display: flex;
  justify-content:center;
  align-items: center;
  max-width: 980px;
  margin: 20px auto;
  flex-wrap: wrap;
}

.filtro button{
  border: 2px solid #0e8e9c;
  background-color: #19C8DB;
  color: #fff;
  font-weight: bold;
  border-radius: 12px;
  padding: 10px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  margin: 10px;
  text-transform: uppercase;
}

.hide{
  display: none;
}

.filtro-title{
  text-align: center;
}

.filtro button:hover{
  color:#0e8e9c;
  scale: 1.05;
}

button.active{
  color:#0e8e9c;
  scale: 1.05;
}