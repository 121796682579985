.prod-title{
  font-size: 38px;
  text-align: center;
  text-transform: uppercase;
}

.page-prod-area{
  max-width: 1360px;
  margin: auto;
}
