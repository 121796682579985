.btn{
  margin: 15px 0;
  text-align: center;
}

.button{
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  background-color: rgb(60, 174, 209);
  border: transparent;
  border-radius: 40px;
  padding: 15px;
  transition: 0.3s all;
  cursor: pointer;
  margin-right: 16px;
}

.button:hover{
  background-color: rgb(56, 160, 192);
  transform: scale(1.05);
}