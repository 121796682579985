.produtos-area{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: auto;
}

.caixa-produtos{
  display: inline-block;
  width: 280px;
  background: transparent;
  border: 2px transparent solid;
  border-radius: 10px;  
  margin: 20px;
  padding: 8px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 14%), 0px 4px 5px rgb(0 0 0 / 12%), 0px 1px 10px rgb(0 0 0 / 20%);
}

.imagem-produto{
  width:100%;
}

.descricao-produto{
  font-size: 22px;
  text-align: center;
  margin: 10px 0 10px 0;
  overflow: hidden;
  height: 3.6em;
}

.preco-produto{
  position: relative;
  font-weight: bold;
  font-size: 28px;
  margin: 10px;
}

.btn{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.categoria{
  display: none;
}

.pesquisa-errada{
  margin: 240px auto;
  font-size: 24px;
  text-align: center;
}

@media screen and (width: 1024px){
  .produtos-area:last-child{
    justify-content: unset;
  }


}

@media screen and (max-width: 340px){

  .caixa-produtos{
    margin: 20px 0;
  }

}
