.product-area {
  max-width: 1024px;
  height: 80%;
  padding: 20px;
  margin:20px;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 14%), 0px 4px 5px rgb(0 0 0 / 12%), 0px 1px 10px rgb(0 0 0 / 20%);
  display: flex;
}

.titulo-compra{
  text-align: left;
  margin: 0 ;
}

.product-box img {
  width: 100%;
  border-radius: 20px;
}

.product-box {
  width: 50%;
}

.product-info{
  width: 100%;
}

.product-info p{
  font-size: 24px;
}

.descricao-compra{
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.descricao-compra p{
  font-style: italic;
  margin-top: 16px;
}

.desc-produto{
  color: #00000070;
  margin: 16px 0 0 0;
}

.produto-btns{
  display: flex;
  margin: 32px 0;
}

@media screen and (max-width: 995px) {
  
  .product-area{
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .product-box{
    width: 100%;
    margin-bottom: 20px;
  }

  .descricao-compra{
    width: 100%;

  }

}

@media screen and (max-width: 600px) {

  .product-box{
    width: 100%;
  }

}

@media screen and (max-width: 485px) {

  .produto-btns{
    flex-direction: column;
  }
}
