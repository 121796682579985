.carousel {
  max-width: 92%;
  margin: auto;
}

.slide {
  display: flex;
  justify-content: center;
}

.slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .carousel{
    max-width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .carousel{
    max-width: 90%;
  }
}