footer{
  background-color: #19C8DB;
  width: 100%;
  bottom: 0;
}

.footer-area{
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.copyright{
  color: white;
  text-align: center;
}

.footer-content{
  display: flex;
  align-items: center;
  flex-direction: column;
}
    